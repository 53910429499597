import React from 'react';
import { Page, Seo, ContactSection } from 'gatsby-theme-portfolio-minimal';
import { Section } from 'gatsby-theme-portfolio-minimal/src/components/Section';

export default function IndexPage() {
  return (
    <>
      <Seo title="Viral Ruparel - Mentorship Program" />
      <Page useSplashScreenAnimation>
        <Section heading={'Mentorship Program'}>
          <h3>
            So, you’re studying engineering preparing for your dream job, what
            now?
          </h3>
          <p>
            There are thousands of ways to build skills and build a career
            through trial and error. But it can be a long process. Having a
            dedicated mentor to guide you, provide you feedback, keep you on the
            right track is essential to success in a competitive job world.
          </p>
          <h3>What is a Mentorship Program?</h3>
          <ul>
            <li>
              <h4>Personalized Mentoring</h4>
              <p>
                Many people leave their dreams because they don’t answer their
                questions. But what if you get a personal mentor who will help
                you find the answer to those essential questions? Viral provides
                one-on-one mentorship to students to understand their issues and
                help them find ways to grow their skills. Viral’s mentorship
                program is entirely personalized.
              </p>
            </li>
            <li>
              <h4>Broaden Professional Network</h4>
              <p>
                Building a network can be a daunting and time-consuming task.
                However, Viral’s mentorship program makes it easy to broaden
                your professional network. He trains students to be better at
                networking by helping them develop core communication skills
                such as empathy. In addition, he provides strategies and tips.
              </p>
            </li>
            <li>
              <h4>Help Get Top Packages In Silicon Valley </h4>
              <p>
                With his personal experience working in Silicon Valley, Viral
                knows what it takes to enter the most competitive world of
                Silicon Valley. He provides hidden insights that are not
                available elsewhere to help students get top packages from
                companies in Silicon Valley.
              </p>
            </li>
            <li>
              <h4>Enhance CV</h4>
              <p>
                Most students or graduates have no idea about their skills to
                survive in the software world. Viral provides mentorship to help
                students find the right path and skills to help them stay in the
                highly competitive programming world. By the end, students have
                all the skills they need to enhance their CV and make the first
                significant impression on employers.
              </p>
            </li>
            <li>
              <h4>Increased Self-Confidence</h4>
              <p>
                Whether it’s an ability to share your opinion in a meeting
                comfortably or stand up for yourself in challenging situations,
                self-confidence is the key to succeeding in a professional
                environment. The mentorship program will help students increase
                their self-confidence by finding flaws and correcting them.
              </p>
            </li>
          </ul>

          <h3>Frequently Asked Questions</h3>
          <p>
            Explore our frequently asked questions and answers on the mentorship
            program.
          </p>
          <ul>
            <li>
              <h4>Who can join the mentorship program? </h4>
              <p>
                Engineering students from the 3rd and 4th years can join Viral’s
                mentorship program. For more information, please visit the
                CONTACT US page.
              </p>
            </li>
            <li>
              <h4>What is the Mentorship program?</h4>
              <p>
                Viral’s mentorship program aims to provide career guidance to
                engineering students from the 3rd and 4th years. The mentorship
                prepares them to crack interviews, update their CV to impress
                employers, improve confidence, etc.
              </p>
            </li>
            <li>
              <h4>
                Why should I join a mentorship program? How will it benefit me?
              </h4>
              <p>
                Engineering students from 2nd and 3rd tier colleges often dream
                of getting a high-paying job with an excellent work-life
                balance. But, unfortunately, without the proper guidance and
                experience, it remains a dream. With the help of his mentorship
                program, Viral assists engineering students in finding the right
                path to their dream jobs.
              </p>
            </li>
            <li>
              <h4>I am a self-learner. Why do I need a mentor?</h4>
              <p>
                There are tons of students who prefer to self-study. However,
                with one-on-one mentorships, you will achieve your career goals
                faster. So, combining your self-study with a mentor who will
                help you when you get stuck and provide feedback is the best
                combo.
              </p>
            </li>
          </ul>
        </Section>
        <ContactSection sectionId="contact" heading="Contact" />
      </Page>
    </>
  );
}
